.wrapper {
    width: 680px;
    height: 540px;
    padding: 10px;
    border-radius: 10px;
    background-color: #485461;
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
    position: absolute;
    margin:0;
    width: auto;
    height: auto;
    z-index: -15;
    top: 30%;
    left: 45%;
    margin: -100px 0 0 -150px;
    /*background: red;*/
}

.screen {
    height: 450px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 10px;
    background-color: #4357692d;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    flex-direction: column;
    align-content: space-around;
    flex-wrap: wrap;
}

.buttonBox {
    width: 100%;
    height: calc(100% - 110px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 10px;
}

button {
    border: none;
    background-color: rgb(80, 60, 209);
    font-size: 24px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
}

button:hover {
    background-color: rgb(61, 43, 184);
}

.equals {
    grid-column: 3 / 5;
    background-color: rgb(243, 61, 29);
}

.equals:hover {
    background-color: rgb(228, 39, 15);
}

button.center {
    margin: 0;
    position: relative;
    top: 50%;
    bottom:0%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(100%, 100%);
}

div{
    font-size:22px;
}

div.screen
{

}

.label {
    display: inline-block;
    position: relative;
    width: 260px;
    margin-top: 5px;
    font-size:22px;
}

.input {
    display: inline-block;
    position: relative;
    width: 150px;
    margin-top: 5px;
    font-size: 22px;
}

.input_hfs {
    display: inline-block;
    position: relative;
    width: 250px;
    margin-top: 5px;
    font-size: 22px;
}

