body {
}

.new-card-form {
    display: flex;
    margin: 20px;
    background-color: #0a2c74;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: all 150ms ease-in-out;
    cursor: pointer;
    height: 100px;
    padding: 35px 40px;
    align-items: center;
    animation: eMLfYp 300ms linear;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    color: white;
    justify-content: space-between;
    flex-direction: column;
}

    .new-card-form h4 {
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 0.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

.new-card-input {
    display: block;
    padding: 1%;
}